import Head from "next/head";
import { Analytics } from "../Analytics/Analytics";
import { RbFooter } from "../rb-footer/rb-footer";
import { CountrySwitcher } from "../country-switcher/country-switcher";
import { Header } from "../header/header";
import { SubAssetType } from "../../helpers/getSubAssetType";
import { LocaleContext } from "../../helpers/LocaleContext";
import { TextDirectionContext } from "../../helpers/TextDirectionContext";
import { KickerKind } from "../kicker/kicker";
import { KickerContext } from "../../helpers/KickerContext";

export const App = ({
  withFooter,
  headerWithdrawn,
  children,
  pageTitle,
  description,
  canonicalUrl,
  socialImage,
  metaRobots,
  locale,
  dir,
  subAssetType,
  title,
  gtmSource,
  kickerKind,
}: {
  pageTitle: string | null;
  description?: string | null;
  canonicalUrl?: string;
  socialImage?: string | null;
  metaRobots?: (string | null)[] | null;
  locale: string;
  dir: "ltr" | "rtl";
  withFooter: boolean;
  headerWithdrawn: boolean;
  children: React.ReactNode;
  subAssetType: SubAssetType | null;
  title: string | null;
  gtmSource: string | null;
  kickerKind: KickerKind;
}) => {
  return (
    <>
      <Analytics
        gtmSource={gtmSource}
        title={title}
        locale={locale}
        subAssetType={subAssetType}
      />
      <Head>
        <title>{pageTitle ?? ""}</title>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={description ?? ""} />
        <meta property="og:title" content={pageTitle ?? ""} />
        <meta property="og:summary" content={description ?? ""} />
        <meta property="og:url" content={canonicalUrl ?? ""} />
        <meta property="og:type" content="product" />
        {socialImage && (
          <>
            <meta property="og:image" content={socialImage} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta name="twitter:card" content="photo" />
            <meta name="twitter:image" content={socialImage} />
            <meta name="twitter:image:width" content="1024" />
            <meta name="twitter:image:height" content="512" />
          </>
        )}
        {metaRobots && <meta name="robots" content={metaRobots.join(", ")} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

        {/* TODO: Temporary test to see if this tag effects the google preview */}
        {/* 10/2023, remove around 03/2024 */}
        {canonicalUrl ===
          "https://www.redbull.com/de-de/energydrink/red-bull-sea-blue-edition-juneberry" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/4kTIhFWASFhI9b2NTMabd3/2e2f2dedab19dae3f27b1d614cc8e3f4/DRES_DE_SEABLUE-Juneberry-250ml_cold_closed_front_redbullcom.png"
          />
        )}

        {canonicalUrl ===
          "https://www.redbull.com/ch-de/energydrink/red-bull-white-edition" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/S3RGeHSI3pECkVg2BVPFL/9cd667a1aa0689806bf07d15b663acf1/CH_RBWH_250_Single-Unit_close_cold_ORIGINAL_canwidth528px.png"
          />
        )}

        {canonicalUrl ===
          "https://www.redbull.com/ch-de/energydrink/red-bull-blue-edition" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/7ortevlkLRolvo5WpXWm4u/52fa641614e89561023754be42f4dbe5/CH_RBBE_250_Single-Unit_close_cold_ORIGINAL_canwidth528px.png"
          />
        )}

        {canonicalUrl ===
          "https://www.redbull.com/de-de/energydrink/red-bull-purple-edition" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/3GeaZh7XeVWAbluGaZJxhX/5eea909ff1ccb600ba17009e1312852e/DE_RBPE_250_Single-Unit_close_cold_ORIGINAL_canwidth528px.png"
          />
        )}

        {canonicalUrl ===
          "https://www.redbull.com/ch-de/energydrink/red-bull-red-edition" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/19mgyjjT436UqQOPvrFlI0/bc7589adc2c35c9eacd92d7c79fbcf27/CH_RED-Watermelon-250ml_cold_closed_001_PCS.png"
          />
        )}

        {canonicalUrl ===
          "https://www.redbull.com/ch-de/energydrink/red-bull-green-edition" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/2c5GV9CU2mZtRzkE3ZN6Y9/b910529fdde9aee9b4b2318713a636f8/CH_RBGE_250_Single_Unit_close_cold_ORIGINAL_LRES__1_.png"
          />
        )}

        {canonicalUrl ===
          "https://www.redbull.com/ch-de/energydrink/red-bull-apricot-edition" && (
          <meta
            itemProp="image primaryImageOfPage"
            content="https://images.ctfassets.net/lcr8qbvxj7mh/129KozOwpFCSAKOJvGaVZ5/cc30609b20d69bcdea5822fbe60f3295/DRES_CH_RBAP_250_Single_Unit_close_cold-1.png"
          />
        )}
        {/* TODO: END */}
      </Head>
      <Header isWithdrawn={headerWithdrawn} locale={locale} dir={dir} />
      <TextDirectionContext.Provider value={dir}>
        <LocaleContext.Provider value={locale}>
          <KickerContext.Provider value={kickerKind}>
            {children}
          </KickerContext.Provider>
        </LocaleContext.Provider>
      </TextDirectionContext.Provider>
      {withFooter && (
        <div>
          <RbFooter locale={locale} dir={dir} />
          <CountrySwitcher initialLocale={locale} />
        </div>
      )}
    </>
  );
};
