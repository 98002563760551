"use client";

import loadScript from "../../helpers/loadScript";
import countryCodes from "../../helpers/countryCodes";
import { useEffect } from "react";

/**
 * This RbFooter component implements the instructions in the Red Bull Platforms
 * documentation. You can read more about that here:
 * @see {@link https://platforms.redbull.com/develop/custom-development/microsites/footers}
 */
export const RbFooter = ({
  locale,
  dir,
}: {
  locale: string;
  dir: "ltr" | "rtl";
}) => {
  useEffect(() => {
    loadScript(
      dir === "rtl"
        ? "https://redbull.com/v3/widgets/footer/footer.rtl.js"
        : "https://redbull.com/v3/widgets/footer/footer.js",
    ).then(() =>
      window.addEventListener("cookiesettingsclick", () =>
        window.Optanon?.ToggleInfoDisplay(),
      ),
    );
  }, [dir]);

  return (
    <div>
      <div>
        {/* @ts-ignore: no type information is available for this web-component */}
        <rbcom-footer
          key={dir}
          theme="light"
          enable-cookie-settings="true"
          locale={
            countryCodes.available.includes(locale)
              ? locale
              : countryCodes.fallback
          }
        />
      </div>
    </div>
  );
};
