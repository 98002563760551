import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

export const LocaleContext = createContext<string | undefined>(undefined);

export const useLocaleContext = (): string => {
  const context = useContext(LocaleContext);

  assertNotNullish(context, "useLocaleContext was used outside of Provider");

  return context;
};
