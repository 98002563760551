"use client";

import loadScript from "../../helpers/loadScript";
import classNames from "classnames";
import { useEffect } from "react";

enum MenuTheme {
  Dark = "dark",
  Light = "light",
}

interface MenuConfig {
  locale: string;
  theme: MenuTheme;
  opaque: boolean;
  accountsDeactivated: boolean;
}
/**
 * This Header component implements the instructions in the Red Bull Platforms
 * documentation for embedding the redbull.com navigation menu. You can read
 * more about that here:
 * @see {@link https://platforms.redbull.com/develop/custom-development/microsites/headers}
 */

export const Header = ({
  isWithdrawn,
  dir,
  locale,
}: {
  isWithdrawn: boolean;
  dir: "ltr" | "rtl";
  locale: string;
}) => {
  // Previously `theme` and `opaque` were configuable in the CMS
  const menuConfig: MenuConfig = {
    locale: locale,
    theme: MenuTheme.Light,
    opaque: true,
    accountsDeactivated: locale === "cn-zh" ? true : false,
  };

  const isDev = process.env.NODE_ENV !== "production";

  useEffect(() => {
    if (window) {
      window.RBGEMC_ACCOUNTS_ENV = isDev ? "design" : "production";
      window.RBGEMC_ACCOUNTS_BUNDLE = `https://${
        isDev ? "stage-account" : "account"
      }.redbull.com/sdk/rba-lightbox.min.js`;
    }
  }, [isDev]);

  useEffect(() => {
    loadScript(
      dir === "rtl"
        ? "https://www.redbull.com/v3/widgets/menu.rtl.js"
        : "https://www.redbull.com/v3/widgets/menu.js",
    );
  }, [dir]);

  return (
    <div
      className={classNames("pcs-global-menu", {
        "pcs-global-menu--withdrawn": isWithdrawn,
      })}
    >
      <div id="rb3-global-menu">
        <div className="rb3-global-menu__wrapper">
          <script
            type="text/props"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(menuConfig) }}
          />
        </div>
      </div>
    </div>
  );
};
